import React from 'react'
import type { HeadProps } from 'gatsby'
import SEO from '~/components/seo'
import Layout from '~/components/layout'
import Profile from '~/components/profile'

interface Props {}

const AboutPage: React.FC<Props> = () => {
  return (
    <Layout>
      <Profile />
    </Layout>
  )
}

export default AboutPage

const SEO_TITLE = `著者紹介`

export const Head: React.FC<HeadProps> = () => {
  return <SEO title={SEO_TITLE} />
}
